import React from 'react'
import { Link } from "react-router-dom";
//components
import ContentWrapper from '../assets/elements/content-wrapper'
import Title from '../assets/elements/section-title'
import ActionButton from '../assets/elements/button/action-button'
//styles
import './styles/empty-basket.css'

function emptyBasket() {
  return (
    <ContentWrapper>
      <Title>My Cart for Quotes</Title>
      <div className='empty-basket__wrapper'>
        <div>You have no properties in your basket yet</div>
        <div>To create a request you can choose one of possible scenarios:</div>
        <div className='empty-basket__wrapper__rows'>
          <div className='empty-basket__wrapper__row'>
            <p>- <Link to="/property-search">
                search property
            </Link>
            <span> and add some of them to the basket</span>
            </p>
            
          </div>
          <div className='empty-basket__wrapper__row'>
            <div>or</div>   
            <Link to="/quick-request">
              <ActionButton 
                action={e=>e.preventDefault()}
              >
                  Create Quick Request
              </ActionButton>
            </Link>
            <span className='empty-basket__wrapper__row__message'>(without choosing the property)</span>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}
export default emptyBasket