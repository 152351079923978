import store from "../store";
import baseService from "../services/base.service";

//actions
import { setCurrencyTypes, setFloorPlanTypes, setAmenities,setAllAmenities, setDictBuildingTypes } from '../actions/dictionary'

const getCurrencyTypes = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/dictionaries/currency`;
    await baseService.get(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
    }).then(({ data }) => {
      let primaryCurrencies = data.filter(item => item.sortOrder !== null).sort((a,b)=> a.sortOrder - b.sortOrder)
      let secondaryCurrencies = data.filter(item => item.sortOrder === null)
      store.dispatch(setCurrencyTypes([
        { key: "Primary", items: primaryCurrencies },
        { key: "Secondary", items: secondaryCurrencies },
    ]))
    }).catch(error => {
        store.dispatch(setCurrencyTypes([]))
    })
  } catch (error) {
    store.dispatch(setCurrencyTypes([]))
  }
}

const getFloorPlanTypes = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/dictionaries/floorplans`;
    await baseService.get(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
    }).then(({ data }) => {
      store.dispatch(setFloorPlanTypes(data))
    }).catch(error => {
        store.dispatch(setFloorPlanTypes([]))
    })
  } catch (error) {
    store.dispatch(setFloorPlanTypes([]))
  }
}

const getAmenities = async () => {
  try {
    const url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/propertyamenity/GetAmenityList`;
    await baseService.get(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
    }).then(({ data }) => {
      let allAmenities = []
      data.forEach(item =>{
        allAmenities.push({name:item.name,id:item.id})
        item.children.forEach(child =>{
          allAmenities.push({name:child.name,id:child.id})
        })
      })
      store.dispatch(setAllAmenities(allAmenities))

      let allData = data
      let headers = []
      data.forEach(item => {
        if(headers.length <= 0)
          headers.push({
            id: item.amenityTypeId,
            name: item.amenityTypeName
          })
        else
        {
          let founded = headers.filter(header => header.id === item.amenityTypeId) 
          if(founded.length <= 0)
            headers.push({
              id: item.amenityTypeId,
              name: item.amenityTypeName
            })
        }
        
      })
      headers.forEach(header => {
          allData = allData.map(item => {
          if(item.parentId === null && (header.id === item.amenityTypeId))
          {
            return{
              ...item,
              parentId: header.name
            }
          }
          else{
            return item
          }
        })

      })
      
      headers.forEach(header => {
        allData.push({
          id: header.name,
          name: header.name
        })
      })
      store.dispatch(setAmenities(allData))
    }).catch(error => {
        store.dispatch(setAmenities([]))
    })
  } catch (error) {
    store.dispatch(setAmenities([]))
  }
}

const getBuildingTypes = async() => {
    try {
        const url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/dictionaries/buildingTypes`;
        await baseService.get(url).then(({ data }) => {
          let formattedData = data.map(item => {
            if(item.id !== 2)
              return item
            else
              return {
                id: 2,
                name: 'Corporate Service Aparment(CS Apartment)'
              }
          })
            store.dispatch(setDictBuildingTypes(formattedData))
        }).catch(error => {
            store.dispatch(setDictBuildingTypes([]))
        })
    } catch (error) {
        store.dispatch(setDictBuildingTypes([]))
    }
}
export { getCurrencyTypes, getFloorPlanTypes, getAmenities, getBuildingTypes }