import baseService from "../services/base.service";
import store from '../store'
import { getPropertyImage, getPrimaryPropertyImage } from '../utils/files'


//utils
import {isLoggedIn,getUserId} from './user'

//constants
import {DEFAULT_VALUES} from '../constant/pagination'
import {BUILDING_TYPES} from '../constant/building-types'
import {MONTH_NAMES,SQUARE_FOOTAGE} from '../constant/dictionary'
import {REQUEST_STATUS,OPTION_STATUS} from '../constant/request.status'


//actions
import {setRequests,setLoading,setRequestOptionDetails,setOptionLoading,setRequestStatus,setAllRequests,setRequestOptionStatus,setRequestsSkip} from '../actions/my-requests'

//devextreme
import notify from 'devextreme/ui/notify';

//images
import PetIcon from '../images/pets_friendly.png'
import RequestHelper from "./helper";

const getPropertyDetails = async (id,radius) => {
  let url = `${process.env.REACT_APP_PROPERTY_API_ENDPOINT}/api/v1/dashboard/${id}/shortinfo`
  let response = await baseService.get(url, {
    headers: {
     'accept': 'application/json',
     'Content-Type': 'application/json'
    }
  })
  if(response.status === 200)
  {
    const {availableFloorPlans,id,propertyImageId,formattedAddress,...rest} = response.data
    return {
      ...rest,
      address: `${formattedAddress} (Distance: ${radius} Miles.)`,
        imageUrl: getPrimaryPropertyImage(id)
    }
  }
  else  
    return {}
}

const getRequestsPerPage = async (skip) => {
  try {
    if(isLoggedIn())
    {
      let userId = getUserId()
      store.dispatch(setLoading(true))
      
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${skip}&take=${DEFAULT_VALUES.take}&requireTotalCount=true`;
     
      await baseService.get(url, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      .then(async (response) => {
        const {data, totalCount} = response.data
        let allFloorPlans = store.getState().dictionary.floorPlanTypes 
        //amenities
        // let allAmenities = []
        // store.getState().dictionary.amenities.forEach(amenitie => {
        //     allAmenities = [
        //         ...allAmenities,
        //         amenitie
        //     ]
        // })
        let formattedData = data.map(async item => {
        
          //floor plan
          
          let floorPlanValue = item.floorPlanTypes.length > 0 ? (allFloorPlans.length === item.floorPlanTypes.length ? 'All Floor Plans' : item.floorPlanTypes.map(item => item.name).join(', ')) : null
          // let floorPlanValue = allFloorPlans.length === item.floorPlanTypes.length ? 'All Floor Plans' : (item.floorPlanTypes[0]?.floorPlanId ? allFloorPlans.filter(floorPlanType => floorPlanType.id === item.floorPlanTypes[0].floorPlanId)[0]?.name : null)

            //date
            let formattedDate = RequestHelper.getDurationString(item.moveIn, item.moveOut)
          
          //options
            let propertyOptions = item.options;

          //currency
          let currencyTypes = store.getState().dictionary.currencyTypes
          let selectedCurrency
          currencyTypes.forEach(currencyType =>{
              let searchCurrency = currencyType.items.filter(currency => currency.id === item.currencyId)
              if(searchCurrency.length > 0)
                selectedCurrency = searchCurrency[0].code
          })

          //buildingtypes
          let buildingTypesNames = ''
          item.buildingTypes.forEach(building => {
            let buildingFound = BUILDING_TYPES.filter(allbuilding => allbuilding.id === building)
            if(buildingFound.length > 0)
              buildingTypesNames += `${buildingFound[0].name}, `
          })
          
          let amenitiesFirstNames = ''
          let amenitiesAllNames = ''

          item.amenities.forEach((amenitie,index) => {
            let amenitieFound = store.getState().dictionary.allAmenities.filter(allamenitie => allamenitie.id === amenitie)
            if(amenitieFound.length > 0 && index < 3)
              amenitiesFirstNames += `${amenitieFound[0].name}, `
            if(amenitieFound.length > 0 && index >= 3)
              amenitiesAllNames += `${amenitieFound[0].name}, `
          })
          
          //preferredProperties
          let formattedPreferedProperties = []

          formattedPreferedProperties = await Promise.all (item.preferredProperties.map(async property => {
            let additionalProperties = await getPropertyDetails(property.propertyId,item.radius)
            
            return {
              id: property.propertyId,
              floorPlan: property?.floorPlanType?.name ? property.floorPlanType.name : '',
              dates: formattedDate,
                ...additionalProperties,
                declineReasonId: property.declineReasonId
            }
          }))
          return ({
              id: item.id,
              title: `${item.preferredProperties.length > 0 ? 'Request' : 'Quick Request'} #${item.id}`,
              isQuickRequest: item.preferredProperties.length <= 0,
              preferredProperties: formattedPreferedProperties,
              propertyOptions: propertyOptions,
              extensions: item.extensions,
              terminations: item.terminations,
              completedExtensions: item.extensions?.filter(item=> item.statusId === 5).map(item => ({
                ...item,
                originStartDate: item.startDate,
                originEndDate: item.endDate,
                startDate: `${new Date(item.startDate).getDate().toString().padStart(2,'0')}/${(new Date(item.startDate).getMonth() + 1).toString().padStart(2,'0')}/${new Date(item.startDate).getFullYear()}`,
                endDate: `${new Date(item.endDate).getDate().toString().padStart(2,'0')}/${(new Date(item.endDate).getMonth() + 1).toString().padStart(2,'0')}/${new Date(item.endDate).getFullYear()}`
              })),
              statusId: item.statusId,
              requirements: {
                address: `${item.formattedAddress} (+${item.radius} miles)`,
                floorPlan: floorPlanValue,
                formattedDate: formattedDate,
                budget: (item.nightlyBudgetMax || item.nightlyBudgetMin) ? (`${typeof item.nightlyBudgetMin === 'number' ? item.nightlyBudgetMin : ''}${item.nightlyBudgetMax && typeof item.nightlyBudgetMin === 'number' ? '-' : ''}${item.nightlyBudgetMax ? item.nightlyBudgetMax : ''}${selectedCurrency ? selectedCurrency : ''}/Nightly`) : null,
                adults: item.totalAdults ? item.totalAdults : 0,
                buildings: buildingTypesNames ? buildingTypesNames.slice(0,-1) : null,
                amenities: amenitiesFirstNames ? amenitiesFirstNames.slice(0,-2) : null,
                allAmenities: amenitiesAllNames ? amenitiesAllNames.slice(0,-2) : null,
                children: item.totalChildren ? item.totalChildren : 0,
                pets: item.petCount ? item.petCount : 0,
                notes: item.notes
              }
          })
        })
        let promiseResolved = await Promise.all(formattedData)
        store.dispatch(setRequests(totalCount,promiseResolved))
      })
      .finally(_ => {
        store.dispatch(setLoading(false))
        window.scrollTo(0,0)
      })
      .catch(error => {
        notify('Something went wrong try again later', "error" , 3000)
      })
    }
    else{
      notify('You need to be logged in', "error" , 3000);
      store.dispatch(setLoading(false))
    }
  } catch (error) {
    store.dispatch(setLoading(false))
  }
}

const postDeclineOption = async (request_id,optionId, declineReasonId, comment) => {
    try {
        store.dispatch(setLoading(true));
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/options/${optionId}/decline`;
        const formData = new FormData();
        formData.append("declineReasonId", declineReasonId);
        formData.append("declineComment", comment);

        await baseService.put(url, formData).then((res) => {
            store.dispatch(setLoading(false));
            store.dispatch(setRequestStatus(request_id,REQUEST_STATUS.Quoted))
            store.dispatch(setRequestOptionStatus(request_id,optionId,OPTION_STATUS.DeclinedByRecipient))
            notify('Option is declined', "success", 3000);
        })

    } catch (error) {
        notify('Something went wrong try again later', "error", 3000);
        store.dispatch(setLoading(false))
    }
}

const putCancelBooking = async(requestId, optionId) => {
    try {
        store.dispatch(setLoading(true));
        const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/${requestId}/options/${optionId}/request-booking-cancellation`;

        await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/request/${requestId}/options/${optionId}/request-booking-cancellation`,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            store.dispatch(setLoading(false));
            store.dispatch(setRequestStatus(requestId, REQUEST_STATUS.PendingManager))
            //TODO UPDATE OPTION STATUS
            notify('Booking cancellation is requested. Please wait our manager to approve cancellation.', "success", 3000);
        })

    } catch (error) {
        notify('Something went wrong try again later', "error", 3000);
        store.dispatch(setLoading(false))
    }
}
const getStatusRequest = async requestId => {
    let response = await baseService({
        method: "GET",
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
        url: `/api/v1/request/${requestId}/status`,
        headers: {
            "Content-Type": "application/json",
        }
    })
    return response?.data
}
const putCancelReservation = async (requestId, optionId) => {
    try {
        store.dispatch(setLoading(true));

        await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: `/api/v1/request/${requestId}/options/${optionId}/cancel-reservation-by-guest`,
            headers: {
                "Content-Type": "application/json",
            },
            // transactionIntegration: true,
        }).then(async (res) => {
            let status = 0
            //TODO CHANGE OPTION STATUS
            while(status != 3){
              status = await getStatusRequest(requestId)
            }
            if(status === 3)
            {
              store.dispatch(setLoading(false));
              store.dispatch(setRequestStatus(requestId, REQUEST_STATUS.Quoted))
              store.dispatch(setRequestOptionStatus(requestId,optionId,OPTION_STATUS.Quoted))
              notify('Reservation is cancelled.', "success", 3000);
            }
        })

    } catch (error) {
        notify('Something went wrong try again later', "error", 3000);
        store.dispatch(setLoading(false))
    }
}

const putAcceptPendingExtension = async (extensionId,requestId) => {
  try {
      store.dispatch(setLoading(true));

      let response = await baseService({
          method: "PUT",
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
          url: `/api/v1/request/accept-pending-extension/${extensionId}`,
          headers: {
              "Content-Type": "application/json",
          },
      })
      if(response.status === 200)
      {
        if (await getRequestOptionExtensionStatus(requestId, extensionId))
          {
            store.dispatch(setLoading(false));
            notify('Pending Extension was accepted.', "success", 3000);
            return true
          }
      }
      else{
        store.dispatch(setLoading(false));
        notify('Something went wrong try again later', "error", 3000);
        return false
      }
  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
      return false
  }
}
const putAcceptPendingTermination = async (requestId) => {
  try {
      store.dispatch(setLoading(true));
      let response =  await baseService({
        method: "PUT",
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
        url: `/api/v1/request/${requestId}/approve-pending-termination`,
        headers: {
            "Content-Type": "application/json",
        },
    })
    
    if(response.status == 200)
    {
      store.dispatch(setLoading(false));
      notify('Pending Termination was accepted.', "success", 3000);
      return true
    }
    else
    {
      notify(response.statusText, "error", 3000);
      store.dispatch(setLoading(false))
      return false
    }
      
          
  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
      return false
  }
}
const putDeclinePendingTermination = async (requestId,termination_id) => {
  try {
      store.dispatch(setLoading(true));

      await baseService({
          method: "PUT",
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
          url: `/api/v1/request/${requestId}/decline-pending-termination`,
          headers: {
              "Content-Type": "application/json",
          },
      }).then(async (res) => {
          if (await getRequestOptionExtensionStatus(requestId, termination_id,true))
          {
            store.dispatch(setLoading(false));
            notify('Pending Termination was declined.', "success", 3000);
          }
      })

  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
  }
}

const putDeclinePendingExtension = async (requestId,extensionId) => {
  try {
      store.dispatch(setLoading(true));

      let response = await baseService({
          method: "PUT",
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
          url: `api/v1/request/decline-pending-extension/${extensionId}`,
          headers: {
              "Content-Type": "application/json",
          },
      })
      if(response.status === 200)
      {
        store.dispatch(setLoading(false));
        notify('Pending Extension was declined.', "success", 3000);
        return true
      }
      else{
        store.dispatch(setLoading(false));
        notify('Something went wrong try again later', "error", 3000);
        return false
      }
  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
      return false
  }
}

const putCancelPendingTermination = async (requestId) => {
  try {
    store.dispatch(setLoading(true));

    await baseService({
        method: "PUT",
        baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
        url: `/api/v1/request/${requestId}/cancel-pending-termination`,
        headers: {
            "Content-Type": "application/json",
        },
        // transactionIntegration: true,
    }).then((res) => {
        store.dispatch(setLoading(false));
        // store.dispatch(setRequestStatus(requestId, REQUEST_STATUS.Quoted))
        //TODO CHANGE OPTION STATUS
        notify('Pending Termination is cancelled.', "success", 3000);
    })

  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
  }
}
const putCancelPendingExtension = async (extensionId) => {
  try {
      store.dispatch(setLoading(true));

      await baseService({
          method: "PUT",
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
          url: `/api/v1/request/cancel-extension/${extensionId}`,
          headers: {
              "Content-Type": "application/json",
          },
          // transactionIntegration: true,
      }).then((res) => {
          store.dispatch(setLoading(false));
          // store.dispatch(setRequestStatus(requestId, REQUEST_STATUS.Quoted))
          //TODO CHANGE OPTION STATUS
          notify('Pending Extension is cancelled.', "success", 3000);
      })

  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
  }
}

const putCancelRequestedProperty = async (requestId, propertyId) => {
    try {
        store.dispatch(setLoading(true));
        const url = `/api/v1/request/${requestId}/property/${propertyId}/cancel-requested-property`;

        let response = await baseService({
            method: "PUT",
            baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
        })

        if(response.status === 200)
        {
          store.dispatch(setLoading(false));
          notify('Property is removed from requested.', "success", 3000);
          return true
        }
        else{
          return false
        }
    } catch (error) {
        notify('Something went wrong try again later', "error", 3000);
        store.dispatch(setLoading(false))
        return false
    }
}

const getRequestOptionDetails = async (requestId,optionId) => {
  try {
    store.dispatch(setOptionLoading(true));
    let url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/${requestId}/options/${optionId}`
    let data = {}
    baseService.get(url, {
      headers: {
       'accept': 'application/json',
       'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.status === 200)
      {
        data = response.data
        
        if(data.option.squareFootageUnit)
        {
          let squareFootageUnitName = SQUARE_FOOTAGE.filter(item => item.id === data.option.squareFootageUnit)[0].name
          data.option.squareFootageUnitName = squareFootageUnitName
        }

        if(data.option.dateOfRenovation)
        {
          let renovatedFormatDate = new Date(data.option.dateOfRenovation)
          let month = renovatedFormatDate.getMonth()
          let monthName = MONTH_NAMES[month]
          let year = renovatedFormatDate.getFullYear()
          data.option.recentlyRenovatedFormatted = `Recently renovated - ${monthName} ${year}`
        }
        else{
          data.option.recentlyRenovatedFormatted = null
        }
        data.option.tags = []
      if(data.option.petsAllowed)
      {
        data.option.tags = [
          ...data.option.tags,
          {
            icon: PetIcon,
            name: 'Pets Friendly'

          }
        ]
      }
      
      if(data.option.optionAmenitiesMapping.length > 0)
      {
        let propertyAmenities = data.option.optionAmenitiesMapping.filter(amenity => amenity.amenityTypeId === 1).map(item => ({
          icon: getPropertyImage(item.iconContainer),
          name: item.amenityName
        }))
        let unitAmenities = data.option.optionAmenitiesMapping.filter(amenity => amenity.amenityTypeId === 2).map(item => ({
          icon: getPropertyImage(item.iconContainer),
          name: item.amenityName
        }))
        data.option.formattedOptionAmenities = [{
          title: 'Property Amenities',
          amenities: propertyAmenities,
          moreButton: propertyAmenities.length > 3 ? true : false,
          activeMoreButton: false
        },
        {
          title: 'Unit Amenities',
          amenities: unitAmenities,
          moreButton: unitAmenities.length > 3 ? true : false,
          activeMoreButton: false
        }
      ]

      }
      data.option.propertyImages = data.option.propertyImages.map(image => {
        return{
          ...image,
          imageUrl: getPropertyImage(image.imageId)
        }
      })
      data.option.propertyFloorPlanImages = data.option.propertyFloorPlanImages.map(image => {
        return{
          ...image,
          imageUrl: getPropertyImage(image.imageId)
        }
      })   

      data.option.currencyName = data.request.currency?.name ? data.request.currency.name : 'USD'
      }
    }).finally(_=>{
      store.dispatch(setRequestOptionDetails(data))
      store.dispatch(setOptionLoading(false));
    }).catch(error => {
      store.dispatch(setOptionLoading(false));
    })    
  } catch (error) {
    store.dispatch(setOptionLoading(false));
  }
}

const getRequestOptionExtensionId = async (requestId,optionId) => {
  try {
    let extensions = await getAllExtensionRequest(requestId)
    let currentExtensions = extensions.length
    let newExtensionAdded = extensions.length
    const startTime = Date.now()
    const timeLimit = 60000
    while (currentExtensions === newExtensionAdded) {
      extensions = await getAllExtensionRequest(requestId)
      newExtensionAdded = extensions.length
      
      if(Date.now() - startTime > timeLimit){
        window.location.reload()
      }
    }
    
    if(newExtensionAdded > currentExtensions)
    {
      extensions = await getAllExtensionRequest(requestId)
      
      return extensions[extensions.length - 1]?.extensionId
    }
  } catch (error) {
    window.location.reload()
  }
}

const getRequestOptionTerminationId = async (requestId) => {
  try {
    let terminations = await getAllTerminationRequest(requestId)
    let currentTerminations = terminations.length
    let newTerminationAdded = terminations.length
    const startTime = Date.now()
    const timeLimit = 60000
    while (currentTerminations === newTerminationAdded) {
      terminations = await getAllTerminationRequest(requestId)
      newTerminationAdded = terminations.length
      
      if(Date.now() - startTime > timeLimit){
        window.location.reload()
      }
    }
    
    if(newTerminationAdded > currentTerminations)
    {
      terminations = await getAllTerminationRequest(requestId)
      
      return terminations[terminations.length - 1]?.id
    }
  } catch (error) {
    window.location.reload()
  }
}

const getRequestOptionExtensionStatus = async (requestId,extensionId,isTermination) => {
  try {
    let currentStatus
    if(isTermination)
    {
      do{
        let terminations = await getAllTerminationRequest(requestId)
        currentStatus = terminations.filter(item => item.id === extensionId)[0].statusId
      }
      while(currentStatus !== 3)
      if(currentStatus === 3)
        return true
    }
    else{
      do{
        let extensions = await getAllExtensionRequest(requestId)
        currentStatus = extensions.filter(item => item.extensionId === extensionId)[0].statusId
        
      }
      while(currentStatus !== 4)
      if(currentStatus === 4)
        return true
    }
  } catch (error) {
    window.location.reload()
  }
}
const postReserveOption = async (_optionId, _requestId) => {
  try {
      store.dispatch(setLoading(true));
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/create-reservation`;
      const formData = new FormData();
      formData.append("requestId", _requestId);
      formData.append("optionId", _optionId);   

      await baseService.post(url, formData).then(async (res) => {
        
        let status = 0
            while(status != 5){
              status = await getStatusRequest(_requestId)
            }
            if(status === 5)
            {
              store.dispatch(setLoading(false));
              store.dispatch(setRequestStatus(_requestId,REQUEST_STATUS.ReservationCreated))
              store.dispatch(setRequestOptionStatus(_requestId,_optionId,OPTION_STATUS.PendingManager))
              notify('You have successfully reserved a stay', "success", 3000);
            }
      })

  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
  }
}

const putCancelRequest = async(requestId) => {
  await baseService({
      method: "PUT",
      baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
      url: `/api/v1/request/cancel/${requestId}`,
      headers: {
          "Content-Type": "application/json",
      },
  }).then(res => {

      store.getState().requests.skip > 0 &&  store.getState().requests.requests.data.length <= 1 && store.dispatch(setRequestsSkip(store.getState().requests.skip - DEFAULT_VALUES.take))

      store.dispatch(setRequestStatus(requestId,REQUEST_STATUS.Cancelled))
  })
}

const getAllExtensionRequest = async (requestId) =>
{
  try {
    if(isLoggedIn())
    {
      let userId = getUserId()
      store.dispatch(setLoading(true))
           
      let totalRequest = await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${1}&requireTotalCount=true`, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      let response =  await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${totalRequest.data.totalCount}&requireTotalCount=true`, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })      
      return response?.data?.data?.filter(item => item.id === requestId)[0]?.extensions
    }
    else{
      return []
    }
  } catch (error) {
    return []

  }
}

const getAllTerminationRequest = async (requestId) =>
  {
    try {
      if(isLoggedIn())
      {
        let userId = getUserId()
        store.dispatch(setLoading(true))
             
        let totalRequest = await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${1}&requireTotalCount=true`, {
          headers: {
           'accept': 'application/json',
           'Content-Type': 'application/json'
          }
        })
        let response =  await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${totalRequest.data.totalCount}&requireTotalCount=true`, {
          headers: {
           'accept': 'application/json',
           'Content-Type': 'application/json'
          }
        })      
        return response?.data?.data?.filter(item => item.id === requestId)[0]?.terminations
      }
      else{
        return []
      }
    } catch (error) {
      return []
  
    }
  }

const getAllRequests = async () => {
  try {
    if(isLoggedIn())
    {
      let userId = getUserId()
      store.dispatch(setLoading(true))
           
      let totalRequest = await baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${1}&requireTotalCount=true`, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
        baseService.get(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/byuser/${userId}?skip=${0}&take=${totalRequest.data.totalCount}&requireTotalCount=true`, {
        headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
        }
      })
      .then(async (response) => {
        const {data} = response.data
          let allFloorPlans = store.getState().dictionary.floorPlanTypes 

          let formattedData = data.map(async item => {
          
            //floor plan
            
            let floorPlanValue = item.floorPlanTypes.length > 0 ? (allFloorPlans.length === item.floorPlanTypes.length ? 'All Floor Plans' : item.floorPlanTypes.map(item => item.name).join(', ')) : null
            // let floorPlanValue = allFloorPlans.length === item.floorPlanTypes.length ? 'All Floor Plans' : (item.floorPlanTypes[0]?.floorPlanId ? allFloorPlans.filter(floorPlanType => floorPlanType.id === item.floorPlanTypes[0].floorPlanId)[0]?.name : null)
  
              //date
              let formattedDate = RequestHelper.getDurationString(item.moveIn, item.moveOut )
            
            //options
              let propertyOptions = item.options;
  
            //currency
            let currencyTypes = store.getState().dictionary.currencyTypes
            let selectedCurrency
            currencyTypes.forEach(currencyType =>{
                let searchCurrency = currencyType.items.filter(currency => currency.id === item.currencyId)
                if(searchCurrency.length > 0)
                  selectedCurrency = searchCurrency[0].code
            })
  
            //buildingtypes
            let buildingTypesNames = ''
            item.buildingTypes.forEach(building => {
              let buildingFound = BUILDING_TYPES.filter(allbuilding => allbuilding.id === building)
              if(buildingFound.length > 0)
                buildingTypesNames += `${buildingFound[0].name}, `
            })
            
            let amenitiesFirstNames = ''
            let amenitiesAllNames = ''
  
            item.amenities.forEach((amenitie,index) => {
              let amenitieFound = store.getState().dictionary.allAmenities.filter(allamenitie => allamenitie.id === amenitie)
              if(amenitieFound.length > 0 && index < 3)
                amenitiesFirstNames += `${amenitieFound[0].name}, `
              if(amenitieFound.length > 0 && index >= 3)
                amenitiesAllNames += `${amenitieFound[0].name}, `
            })
            
            //preferredProperties
            let formattedPreferedProperties = []
  
            formattedPreferedProperties = await Promise.all (item.preferredProperties.map(async property => {
              let additionalProperties = await getPropertyDetails(property.propertyId,item.radius)
              
              return {
                id: property.propertyId,
                floorPlan: property?.floorPlanType?.name ? property.floorPlanType.name : '',
                dates: formattedDate,
                  ...additionalProperties,
                  declineReasonId: property.declineReasonId
              }
            }))
            let petDetailsLabel = ''
            item.pets.forEach(pet => {
              petDetailsLabel = petDetailsLabel + `<li><span>${pet.kindPet}</span> <span>${pet.weight} ${pet.petWeightTypeName}</span></li>`
            })
  
            return ({
                id: item.id,
                title: `${item.preferredProperties.length > 0 ? 'Request' : 'Quick Request'} #${item.id}`,
                isQuickRequest: item.preferredProperties.length <= 0,
                preferredProperties: formattedPreferedProperties,
                propertyOptions: propertyOptions,
                extensions: item.extensions,
                terminations: item.terminations,
                completedExtensions: item.extensions?.filter(item=> item.statusId === 5).map(item => ({
                  ...item,
                  originStartDate: item.startDate,
                  originEndDate: item.endDate,
                  startDate: `${new Date(item.startDate).getDate().toString().padStart(2,'0')}/${(new Date(item.startDate).getMonth() + 1).toString().padStart(2,'0')}/${new Date(item.startDate).getFullYear()}`,
                  endDate: `${new Date(item.endDate).getDate().toString().padStart(2,'0')}/${(new Date(item.endDate).getMonth() + 1).toString().padStart(2,'0')}/${new Date(item.endDate).getFullYear()}`
                })),
                statusId: item.statusId,
                limitDate: item.moveOut,
                requirements: {
                  address: `${item.formattedAddress} (+${item.radius} miles)`,
                  floorPlan: floorPlanValue,
                  formattedDate: formattedDate,
                  budget: (item.nightlyBudgetMax || item.nightlyBudgetMin) ? (`${typeof item.nightlyBudgetMin === 'number' ? item.nightlyBudgetMin : ''}${item.nightlyBudgetMax && typeof item.nightlyBudgetMin === 'number' ? '-' : ''}${item.nightlyBudgetMax ? item.nightlyBudgetMax : ''}${selectedCurrency ? selectedCurrency : ''}/Nightly`) : null,
                  adults: item.totalAdults ? item.totalAdults : 0,
                  buildings: buildingTypesNames ? buildingTypesNames.slice(0,-2) : null,
                  amenities: amenitiesFirstNames ? amenitiesFirstNames.slice(0,-2) : null,
                  allAmenities: amenitiesAllNames ? amenitiesAllNames.slice(0,-2) : null,
                  children: item.totalChildren ? item.totalChildren : 0,
                  pets: item.petCount ? item.petCount : 0,
                  petDetails: item.petCount ? `<div><b>Pets Details</b><hr/> <ul>${petDetailsLabel}</ul></div>`: '',
                  notes: item.notes
                }
            })
          })
          let promiseResolved = await Promise.all(formattedData)
          store.dispatch(setAllRequests(promiseResolved))        
      })
      .finally(_ => {
          store.dispatch(setLoading(false))
          window.scrollTo(0,0)
      })
      .catch(error => {
        notify('Something went wrong try again later', "error" , 3000)
      })
    }
    else{
      notify('You need to be logged in', "error" , 3000);
      store.dispatch(setLoading(false))
    }
  } catch (error) {
    notify('Something went wrong try again later', "error" , 3000);
    store.dispatch(setLoading(false))
  }
}


const getSelectedRequestByType = (skip,type) =>{


  let allRequests = store.getState().requests.allRequest
  let total = 0
  let selectedRequests = []
  switch (type) {
      case 'cancel':
          selectedRequests = allRequests?.filter((item) => item.statusId === REQUEST_STATUS.Cancelled || item.statusId === REQUEST_STATUS.CancelledByManager || item.statusId === REQUEST_STATUS.Expired)
      break;
    case 'active':
          selectedRequests = allRequests?.filter((item) => (item.statusId !== REQUEST_STATUS.Cancelled && item.statusId !== REQUEST_STATUS.CancelledByManager && item.statusId !== REQUEST_STATUS.Expired)  && (new Date(item.limitDate) >= new Date() || item.statusId === REQUEST_STATUS.PendingExtension))
          // selectedRequests = allRequests?.filter((item) => item)
      break;
    case 'past':
          selectedRequests = allRequests?.filter((item) => (item.statusId !== REQUEST_STATUS.PendingExtension && item.statusId !== REQUEST_STATUS.Cancelled && item.statusId !== REQUEST_STATUS.CancelledByManager && item.statusId !== REQUEST_STATUS.Expired) && new Date(item.limitDate) < new Date())
      break;
    default:
      selectedRequests = []
      break;
  }
  total = selectedRequests.length

  store.dispatch(setRequests(total,selectedRequests.filter((item,index) => index >= (skip) && index <= (DEFAULT_VALUES.take + (skip-1)))))
}

const getOasisContact = async(requestId) => {
  try {
      store.dispatch(setLoading(true));
      const url = `/api/v1/request/${requestId}/manager-info`;

      let response = await baseService({
          method: "GET",
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
          url: url,
          headers: {
              "Content-Type": "application/json",
          },
      })
      let data = {
        fullName: `${response?.data?.firstName} ${response?.data?.lastName}`,
        email: response?.data?.email,
        phone: response?.data?.fullPhone
      }
      return data

  } catch (error) {
      return {}
  }
}

const postRequestExtension = async (request_id,option_id,startDate, endDate, comment) => {
  try {
      store.dispatch(setLoading(true));
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/request-extension-by-guest`;
      const data = {
        requestId: request_id,
        startDate: startDate,
        endDate: endDate,
        comment:comment
      }
      let response = await baseService.post(url, data)
      if(response.status === 200)
      {
        let id = await getRequestOptionExtensionId(request_id,option_id)
        notify('Extension is requested', "success", 3000);
        store.dispatch(setLoading(false));
        return id
      }
      else{
        return false
      }
  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
      return false
  }
}

const postRequestTermination = async (request_id,option_id,terminationDate, comment) => {
  try {
      store.dispatch(setLoading(true));
      const url = `${process.env.REACT_APP_API_ENDPOINT}/api/v1/request/request-termination-by-guest`;
      const data = {
        requestId: request_id,
        terminationDate: terminationDate,
        residentComment:comment
      }
      let response = await baseService.post(url, data)
      if(response.status === 200)
      {
        let id = await getRequestOptionTerminationId(request_id,option_id)

        store.dispatch(setLoading(false));

        notify('Termination is requested', "success", 3000);
        // store.dispatch(setLoading(false));
        return id
      }
      else{
        notify('Something went wrong try again later', "error", 3000);
        return null
      }
  } catch (error) {
      notify('Something went wrong try again later', "error", 3000);
      store.dispatch(setLoading(false))
      return null
  }
}

export {
    getRequestOptionDetails,
    getRequestsPerPage,
    postReserveOption,
    putCancelRequest,
    getAllRequests,
    getSelectedRequestByType,
    postDeclineOption,
    putCancelBooking,
    getOasisContact,
    putCancelRequestedProperty,
    postRequestExtension,
    postRequestTermination,
    putCancelReservation,
    putCancelPendingExtension,
    putCancelPendingTermination,
    putAcceptPendingExtension,
    putDeclinePendingTermination,
    putAcceptPendingTermination,
    putDeclinePendingExtension
}