export const BUILDING_TYPES = [
    {
        id: 1,
        name: 'Hotel',
        longName: 'Hotel',
        shortName: 'Hotel'
    },
    {
        id: 2,
        name: 'Corporate Serviced Apartment (CS Apartment)',
        longName: 'Corporate Serviced Apartment',
        shortName: 'CS Apartment'
    },
    {
        id: 4,
        name: 'Single Family Home',
        longName: 'Single Family Home',
        shortName: 'Single Family Home'
    },
    {
        id: 3,
        name: 'Town House',
        longName: 'Town House',
        shortName: 'Town House'
    },
    { 
        id: 10,
        name: 'Oasis Collections Exclusive Properties',
        longName: 'Oasis Collections Exclusive Properties',
        shortName: 'Oasis Collections Exclusive Properties'
    }
]

export const BUILDING_TYPES_NAMES = [
    {
        id: 1,
        longName: 'Hotel',
        shortName: 'Hotel'
    },
    {
        id: 2,
        longName: 'Corporate Serviced Apartment',
        shortName: 'CS Apartment'
    },
    {
        id: 8,
        longName: 'Corporate Serviced Apartment',
        shortName: 'CS Apartment'
    },
    {
        id: 4,
        longName: 'Single Family Home',
        shortName: 'Single Family Home'
    },
    {
        id: 3,
        longName: 'Town House',
        shortName: 'Town House'
    },
    {
        id: 9,
        longName: 'Town House',
        shortName: 'Town House'
    },
    { 
        id: 10,
        longName: 'Oasis Collections Exclusive Properties',
        shortName: 'Oasis Collections Exclusive Properties'
    }
]
