import {useState,useEffect,useCallback,memo,useRef} from 'react'
import {useParams} from 'react-router-dom'
import {debounce} from 'lodash'
//components
import { TextBox } from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule,
  CustomRule
} from 'devextreme-react/validator';

//utils
import { getGeocoding } from '../../../../utils/search-properties'

//constant
import { LATITUDE_DEFAULT, LONGITUDE_DEFAULT } from '../../../../constant/default-properties'
//styles
import '../../styles/input/custom-input-alt.css'

const InputSearch = ({label,location,onChange,placeholder,inputId,classCustom,disable,requiredValue,clearField,setClearField,setIsSelected,inputIcon,noBorders,mode,setMode}) => {
  // const params = useParams()
  const refValidator = useRef()
  const [isFocus, setisFocus] = useState()
  const [isOnEnter, setisOnEnter] = useState(false)
  const [isOnPlaceChanged, setIsOnPlaceChanged] = useState(false)

  const [valueSearch,setValueSearch] = useState(mode && mode.type === 'map' ? null  :  (location?.value) ? location.value : '')
  const [placeId, setPlaceId] = useState(mode && mode.type === 'map' ? ''  : location ? location : '')
  let autocomplete = {}
  let autocompleteservice = {}

  const onChangeValueSearch = data => {
    setValueSearch(data)
    setMode && setMode('field',null)
  }

  const onPlaceChanged = () => { 
    setIsOnPlaceChanged(true)
    const place = autocomplete.getPlace()

    if(Object.keys(place).length > 1)
    {
      if(!place.place_id)
        onChange({
          id:'',
          value:''
        })
      setValueSearch(document.querySelector(`#${inputId}`)?.value)
    }
  }
  
  const getPlaceId = (value) => {

    if (value)
      autocompleteservice.getQueryPredictions({ 'input': value }, (prediction) => {

        let place = []
        if (prediction) {
          place = prediction.filter(result => {
            return result.description.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC') === value.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f']/g, "").normalize('NFC')
          })
        }
        setPlaceId({
          id: place.length >= 1 ? place[0].place_id : '',
          value: value
        })
      })
    else
      setPlaceId({
        id: '',
        value: value
      })

  }
  
  const onChangeSearch = useCallback(debounce(value => {
    getPlaceId(value)
  }, 500), [])
  const onEnterChange = useCallback(e=>{
    if(e.key === 'Enter')
      setisOnEnter(true)
    else
    {
      setisOnEnter(false)
      setIsOnPlaceChanged(false)
    }
  },[])
  
  useEffect(() => {
      autocomplete = {}
      autocompleteservice={}
      autocomplete = new window.google.maps.places.Autocomplete(document.querySelector(`#${inputId}`))
      autocompleteservice = new window.google.maps.places.AutocompleteService()     
      autocomplete.addListener('place_changed', onPlaceChanged) 
      window.addEventListener('keydown', onEnterChange)
      return () => {
        window.removeEventListener('keydown', onEnterChange)
      }
  }, [])
  
  useEffect(() => {
    if(!clearField)
    {
      if(!valueSearch)
      {
          onChange({
            id:'',
            value:''
          })
      }
      onChangeSearch(valueSearch)
    }
  }, [valueSearch])  
  
  useEffect(() => {
    onChange({
      id: placeId.id,
      value: placeId.value
    })

  }, [placeId])


  const getValidPlaceId = e => {
    return !isFocus ? placeId.id : true
  }

  useEffect(() => {
    if(clearField)
    {
      setValueSearch('') 
      if(refValidator?.current?.instance?.option('isValid') === false)
        refValidator?.current?.instance?.reset() 
    }
  }, [clearField,location])
 
  const onValidated = useCallback(e => {
    if(clearField && !valueSearch)
    {
      if(refValidator?.current?.instance?.option('isValid') === false) 
        refValidator?.current?.instance?.reset()
      setClearField(false)
    }
      let inputHeight = document.querySelector(`#${inputId}`).offsetHeight
      let validationPosition = inputHeight + 35
      let overlay = e.element?.querySelector(".dx-invalid-message-content")
      if(overlay)
        overlay.style.top=`-${validationPosition}px`
  },[clearField,valueSearch])
  const onKeyValidation = useCallback(e=>{
    let regex = /[<>]/
    regex.test(e.event.key) && e.event.preventDefault()
  },[])

  useEffect(() => {
    if(mode && mode.type === 'map')
    {

    }
    else{
      if(location?.value)
        setValueSearch(location.value)
    }
    
  }, [location])

useEffect(() => {
  if(setIsSelected)
  {
    if(location?.id && isOnEnter && isOnPlaceChanged)
    {
       setIsSelected(true)
    }
    else  
      setIsSelected(false)
  }
}, [isOnEnter,location,isOnPlaceChanged])
  useEffect(() => {

    if(!isFocus)
    {
      setIsOnPlaceChanged(false)
      setisOnEnter(false)
    }
  }, [isFocus])
  

  return (
    <div className={`custom-input-alt ${noBorders ? 'custom-input-alt--no-borders':''}`}>
      {label && <div className='custom-input-alt__label'>{label}:{requiredValue ? <span>*</span> : ''}</div>}
        <div className={`custom-input-alt__container ${inputIcon ? '' : 'custom-input-alt__container--noIcon'}`}>
        <TextBox
            inputAttr={{
              id: inputId
            }}
            className={classCustom}
            disabled={disable}
            placeholder={placeholder}
            value={valueSearch}
            valueChangeEvent="input"
            onValueChange={onChangeValueSearch}
            onKeyDown={onKeyValidation}
          >
              
                <Validator 
                  ref={refValidator}
                  onValidated={onValidated}
                >
                  {requiredValue && <RequiredRule message={`${label ? label : 'location'} is required`} />}
                  {requiredValue && <CustomRule
                          validationCallback={getValidPlaceId}
                          message={`${label ? label : 'location'} is not valid`}
                      />}
                </Validator>
          </TextBox>
          <div className='custom-input-alt__container__icon'><img src={inputIcon}/></div>
          
        </div>
    </div>
  )
}

export default InputSearch