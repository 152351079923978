import { connect } from 'react-redux'


import MyBasketContent from './my-basket/my-basket-content'
import EmptyBasket from './my-basket/empty-basket'

import { Navigate, useLocation } from 'react-router-dom';

import {isLoggedIn} from '../utils/user'
const MyBasket = ({basketData}) => {
    const location = useLocation();
    
    return (
        isLoggedIn()  ? (basketData?.data?.length > 0 ? <MyBasketContent /> : <EmptyBasket/>) : <Navigate to="/login" state={{ from: location }} replace />
    );
}

function mapStateToProps({ basket }) {
    return {
      basketData: basket.allBasket,
    };
  }
  export default connect(mapStateToProps)(MyBasket);